import { ProductEntity } from '@/model/product';
import { updateCartStore } from '@/storage/session/manager';
import { CartEntity } from '@/model/cart';

export type CartProductsAction = { type: CartProductsActionKind; product: ProductEntity };
export type CartProductsState = {
    products: ProductEntity[];
    count: number;
    totalPrice: number;
};

export enum CartProductsActionKind {
    ADD_PRODUCT = 'ADD_PRODUCT',
    REMOVE_PRODUCT = 'REMOVE_PRODUCT'
}

export function cartProductsReducer(
    state: CartProductsState,
    action: CartProductsAction
): CartProductsState {
    switch (action.type) {
        case CartProductsActionKind.ADD_PRODUCT: {
            const product_is_in_the_cart = state.products.some(
                (product: ProductEntity) => product.id == action.product.id
            );

            if (product_is_in_the_cart) return state;

            const new_cart: CartEntity = {
                products: [...state.products, action.product],
                number_of_items: state.count++,
                total_price: state.totalPrice + action.product.price
            };

            updateCartStore(new_cart);

            return {
                products: new_cart.products,
                count: new_cart.number_of_items,
                totalPrice: new_cart.total_price
            };
        }
        case CartProductsActionKind.REMOVE_PRODUCT: {
            const new_cart: CartEntity = {
                products: state.products.filter((p) => p.id !== action.product.id),
                number_of_items: state.count--,
                total_price: state.totalPrice - action.product.price
            };

            updateCartStore(new_cart);

            return {
                products: new_cart.products,
                count: new_cart.number_of_items,
                totalPrice: new_cart.total_price
            };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
