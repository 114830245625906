'use client';
import { CartEntity } from '@/model/cart';
import React, { Dispatch, createContext, useContext, useReducer } from 'react';
import { CartProductsAction, CartProductsState, cartProductsReducer } from './reducer';
import { getCart } from '@/storage/session/manager';

function initCartProducts(cart: CartEntity): CartProductsState {
    return {
        products: cart.products,
        count: cart.number_of_items,
        totalPrice: cart.total_price
    };
}

export const CartProductsContext = createContext<CartProductsState>(initCartProducts(getCart()));

export const CartProductsDispatchContext = createContext<Dispatch<CartProductsAction>>(() => {});

export function useCartProducts() {
    return useContext(CartProductsContext);
}

export function useCartProductsDispatch() {
    return useContext(CartProductsDispatchContext);
}

export function CartProductsProvider({ children }: { children: React.ReactNode }) {
    const [products, dispatch] = useReducer(cartProductsReducer, getCart(), initCartProducts);

    return (
        <CartProductsContext.Provider value={products}>
            <CartProductsDispatchContext.Provider value={dispatch}>
                {children}
            </CartProductsDispatchContext.Provider>
        </CartProductsContext.Provider>
    );
}
