'use client';

import { CartEntity } from '@/model/cart';
import {
    BillingInfo,
    BillingInfoZeroValue,
    ShippingAddress,
    ShippingAddressZeroValue
} from '@/model/address';

const orderStorageKey = 'emregnigx89526redro';
const orderBillingInfoStorageKey = 'emregnigx89526gnillib';
const orderShippingAddressStorageKey = 'emregnigx89526gnippihs';

function saveData(key: string, data: string) {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, data);
    }
}

function getData<T>(key: string, defaultValue: T): T {
    if (typeof window !== 'undefined') {
        const data = sessionStorage.getItem(key);
        return data != null ? JSON.parse(data) : defaultValue;
    }

    return defaultValue;
}

export function updateCartStore(cart: CartEntity) {
    saveData(orderStorageKey, JSON.stringify(cart));
}

export function storeBillingInfo(data: BillingInfo) {
    saveData(orderBillingInfoStorageKey, JSON.stringify(data));
}

export function storeShippingAddress(data: ShippingAddress) {
    saveData(orderShippingAddressStorageKey, JSON.stringify(data));
}

export function getCart(): CartEntity {
    const emptyCart = { products: [], number_of_items: 0, total_price: 0 };
    return getData<CartEntity>(orderStorageKey, emptyCart);
}

export function getBillingInfo(): BillingInfo {
    return getData<BillingInfo>(orderBillingInfoStorageKey, BillingInfoZeroValue);
}

export function getShippingAddress(): ShippingAddress {
    return getData<ShippingAddress>(orderShippingAddressStorageKey, ShippingAddressZeroValue);
}
