export interface BillingInfo {
    email: string;
    firstName: string;
    lastName: string;
}

export const BillingInfoZeroValue: BillingInfo = {
    email: '',
    firstName: '',
    lastName: ''
};

export interface ShippingAddress {
    address: string;
    zipcode: string;
    city: string;
    country: string;
}

export const ShippingAddressZeroValue: ShippingAddress = {
    address: '',
    zipcode: '',
    city: '',
    country: ''
};
